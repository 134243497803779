<template>
  <div>
    <p
      v-for="(sscNote, index) in sscNotes"
      class="mb-1"
      :key="sscNote.id"
    >
      <a
        v-if="currentUser.isAdmin || currentUser.id === sscNote.user.id"
        href="javascript:;"
        v-b-tooltip.hover.top="'Delete Note'"
        class="text-danger font-weight-bold mr-1"
        @click="onNoteDelete(index)"
      ><b-icon-dash-square></b-icon-dash-square></a>
      <span v-html="$options.filters.nl2br(sscNote.note)"></span>
    </p>
    <p><small><a href="javascript:;" @click="onNewNote">New Note</a></small></p>
    <b-modal 
      id="modal-ssc-note"
      v-model="sscNoteFormShow"
      title="SSC Note"
      hide-footer
    >
      <SscNoteForm
        :franchiseCode="franchiseCode"
        :noteId="sscNoteId"
        @ssc-note-saved="onNoteSaved()"
        @ssc-note-canceled="sscNoteFormShow = !sscNoteFormShow"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SscNoteForm from '@/components/SscNoteForm'

export default {
  name: 'SscNotes',
  props: {
    franchiseCode: String,
  },
  components: { SscNoteForm },
  data () {
    return {
      sscNoteFormShow: false,
      sscNoteId: null
    }
  },
  computed: {
    ...mapGetters([
      'sscNotes',
    ]),
  },
  methods: {
    ...mapActions([
      'getSscNotes',
      'putSscNote',
      'resetSscNotes'
    ]),
    onNewNote () {
      this.sscNoteFormShow = true
    },
    onNoteSaved () {
      this.sscNoteFormShow = false
      this.onGetSscNotes()
    },
    onNoteDelete (index) {
      const note = this.sscNotes[index]
      if  (!(this.currentUser.isAdmin || this.currentUser.id === note.user.id)) {
        return this.$bvModal.msgBoxOk('You are not authorized!')
      }
      this.$bvModal.msgBoxConfirm('Delete the selected note?')
      .then(async confirm => {
        if (!confirm) return
        await this.putSscNote({
          id: note.id,
          deletedBy: this.currentUser['@id']
        })
        this.onGetSscNotes()
      })
    },
    onGetSscNotes () {
      if (!this.franchiseCode) {
        console.error('missing this.franchiseCode present')
        return;
      }
      this.getSscNotes({
        franchiseCode: this.franchiseCode,
        'exists[deletedBy]': false
      })
    }
  },
  mounted () {
    this.onGetSscNotes()
  },
  destroyed () {
    // this.resetSscNotes()
  }
}
</script>
