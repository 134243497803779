<template>
  <div>
    <b-form v-if="note" ref="formSscNote" @submit.prevent="onSubmit" @reset="onReset">
      <b-alert variant="danger" v-model="showValidationError">{{ validationError }}</b-alert>
      <b-form-group
        id="fieldset-ssc-note-note"
        description="Keep notes to a maximum of..."
        label="SSC Note"
        label-for="ssc-note-note"
        label-sr-only
      >
        <b-form-textarea
          id="ssc-note-note"
          v-model="note.note"
          placeholder="SSC note..."
          rows="2"
          max-rows="6"
        ></b-form-textarea>
      </b-form-group>
      <BaseFormButtons
        @form-button-submit="onSubmit"
        @form-button-reset="$refs.formSscNote.reset()"
        @form-button-cancel="onCancel()"
        :updating="(typeof note.id !== 'undefined')"
      />
    </b-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
const DEFAULT_FORM = {
  note: null
}
export default {
  name: "SscNoteForm",
  props: {
    noteId: Number,
    franchiseCode: String,
  },
  data() {
    return {
      note: null,
      validationError: null
    }
  },
  computed: {
    ...mapGetters(['sscNotes']),
    showValidationError () {
      return (this.validationError !== null)
    }
  },
  methods: {
    ...mapActions([
      'getSscNotes',
      'postSscNote',
      'putSscNote'
    ]),
    validate (note) {
      this.validationError = null
      if (!note.franchiseCode) {
        this.validationError = 'A franchise code is required'
      }
      if (!note.user) {
        this.validationError = 'A user ID is required'
      }
      if (!note.note) {
        this.validationError = 'A note is required'
      }
      if (!this.validationError) {
        return true
      }
      return false
    },
    async onSubmit () {
      (this.note.id) ? await this.putNote() : await this.postNote()
      if (!this.validationError) {
        this.$emit('ssc-note-saved')
        this.note = { ...DEFAULT_FORM }
      }
    },
    async postNote () {
      const note = { ...this.note }
      note.user = this.currentUser['@id']
      note.franchiseCode = this.franchiseCode
      if (!this.validate(note)) return false
      return await this.postSscNote(note)
    },
    async putNote () {
      const note = { ...this.note }
      if (!this.validate(note)) return false
      return await this.putSscNote(note)
    },
    onReset () {
      this.validationError = null
      this.note.note = null
    },
    onCancel () {
      this.onReset()
      this.$emit('ssc-note-canceled')
    }
  },
  async mounted () {
      this.note = { ...DEFAULT_FORM }
    if (!this.noteId) return
    await this.getSscNotes({
      id: this.noteId
    })
    this.note = this.sscNotes[0]
  }
}
</script>